.rbt-contact-form {
    &.contact-form-style-1 {
        padding: 50px;
        position: relative;
        z-index: 1;
        max-width: 490px;
        padding-left: 60px;
        z-index: 2;
        background: var(--color-white);
        box-shadow: var(--shadow-1);
        border-radius: var(--radius);

        @media #{$lg-layout} {
            padding: 30px 35px;
        }

        @media #{$md-layout} {
            max-width: inherit;
        }

        @media #{$sm-layout} {
            padding: 30px 20px;
            padding-left: 20px;
            max-width: inherit;
        }

        .callto-action-wrapper {
            span {
                &.text {
                    font-size: 15px;
                    line-height: 24px;
                    display: inline-block;
                    padding-right: 10px;
                }

                i {
                    font-size: 22px;
                    font-weight: 400;
                    color: var(--color-heading);
                    line-height: 26px;
                    padding-right: 4px;
                }

                a {
                    font-size: 22px;
                    font-weight: 700;
                    color: var(--color-heading);
                    line-height: 26px;
                    text-decoration: none;
                    @extend %transition;

                    @media #{$sm-layout} {
                        font-size: 14px;
                    }

                    &:hover {
                        color: var(--color-primary);
                    }
                }
            }
        }

        .shape-group {
            @media #{$lg-layout} {
                display: none;
            }

            .shape {
                position: absolute;
                right: -193px;
                top: 50%;
                z-index: -1;

                @media #{$lg-layout} {
                    right: -151px;
                }

                @media #{$sm-layout} {
                    display: none;
                }

                &.shape-01 {
                    right: -64px;
                    top: 26%;
                }
            }
        }
    }
}

/* Form Group  */
.form-group {
    position: relative;
    z-index: 2;
    margin-bottom: 30px;
    display: block;

    label {
        // position: absolute;
        left: 0;
        width: 100%;
        top: 9px;
        color: var(--color-body);
        transition: 0.3s;
        z-index: -1;
        letter-spacing: 0.5px;
        font-size: 18px;
        line-height: 28px;
    }

    input,
    textarea {
        border: 0;
        border-bottom: 2px solid var(--color-border);
        background-color: transparent;
        padding-bottom: 8px;
        padding-top: 20px;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
    }


    input[type="submit"] {
        border: 2px solid var(--color-heading);
        background: #ffffff;
        color: var(--color-heading);
        font-size: 16px;
        font-weight: 700;
        height: 46px;
        line-height: 44px;
        padding: 0;
        text-transform: capitalize;
        @extend %transition;

        &:hover {
            background: var(--color-heading);
            color: #ffffff;
        }
    }

    textarea {
        border: 0;
        padding-bottom: 8px;
        padding-top: 8px;
        border-bottom: 2px solid var(--color-border);
        background-color: transparent;
        resize: none;
        min-height: 133px;
    }

    span {
        &.focus-border {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px;
            background-color: var(--color-primary);
            transition: 0.4s;
        }
    }

    &.focused {
        label {
            top: -10px;
            font-size: 12px;
            color: var(--color-primary);
            transition: 0.3s;
        }

        span {
            &.focus-border {
                width: 100%;
                transition: 0.4s;
            }
        }
    }
}



.upload-area {
    label {
        width: 100%;
        height: 250px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        flex-direction: column;
        margin-bottom: 15px;

        i {
            font-size: 40px;
            stroke-width: 1px;
            color: var(--color-primary);
            margin-bottom: 10px;
        }

        p {
            font-size: 14px;
            font-weight: 300;
            color: var(--color-white);
        }

        span {
            font-size: 20px;
            color: var(--color-white);
        }
    }

    .upload-formate {
        h6 {
            &.title {
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 10px;
            }
        }

        p {
            &.formate {
                font-size: 16px;
            }
        }
    }

    .brows-file-wrapper {
        position: relative;
        border: 2px dashed #575767;
        border-radius: 5px;

        input {
            position: absolute;
            height: 100%;
            width: 100%;
            opacity: 0;
            cursor: pointer;
            z-index: 10;
        }

        label {
            position: relative;
            z-index: 10;
            transition: var(--transition);
        }

        img {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 9;
            cursor: pointer;

            border-radius: 5px;
            object-fit: cover;
        }

        &:hover {
            label {
                transform: scale(0.8);
            }
        }
    }
}