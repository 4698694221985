/*---------------------
    Social Share  
-----------------------*/
.social-share-transparent {
        position: absolute;
        display: block;
        right: 30px;
        
    // display: flex;
    @extend %liststyle;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: -10px;
    margin-top: 250px;
    @media #{$large-mobile} {
        // margin: -5px;
        // margin: 20px;
        // display: flex;
        display: none;
    }
    li {
        margin: 0;
        position: relative;
        a {
            font-size: 14px;
            color: #000;
            display: inline-block;
            @extend %transition;
            display: flex;
            align-items: center;
            width: 30px;
            height: 30px;
            text-align: center;
            justify-content: center;
            border-radius: 100%;
            position: relative;
            z-index: 1;
            &::before {
                // background: var(--color-gray-light);
                background: #000;
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                @extend %transition;
                opacity: 0;
                transform: scale(0.8);
                border-radius: 100%;
                z-index: -1;
            }
            i {
                margin-right: 0px;
            }
            &:hover {
                // color: var(--color-primary);
                color: #fff;
                &::before {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }
}

.tiktok-icon svg {
    fill: #000;
    height: 12px;
}
.tiktok-icon-mob svg {
    fill: black;
    height: 12px;
}

.tiktok-icon-mob:hover svg {
    fill: #000;
}

.tiktok-icon:hover svg {
    fill: #fff;
}

.social-share-transparent {
    @media #{$md-layout} {
        margin-top: 100px;
    }
}
