/*----------------------------
    Blog Details Styles  
----------------------------*/
.blog-content-wrapper {
    audio {
        display: block;
        width: 100%; 
    }

    .embed-responsive {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
    }

    iframe {
        border: 0;
        width: 100%;
        border-radius: 10px;
        &.square {
            border-radius: 0;
        }
    }
    

    .post-thumbnail {
        img {
            width: 100%;
        }
    }

    p {
        line-height: 1.67;
        a {
            // color: var(--color-primary);
            color: black;
        }
        li.programLink {
            margin-top: 0px;
            margin-bottom: 0px;
            padding: 10px;
            border-radius: 5px;
            width: 300px;
        }
        li.programLink:hover {
            // color: var(--color-primary);
            color: white;
            background-color: black;
            cursor: pointer;
        }
        li.programLink.active {
            // color: var(--color-primary);
            color: white;
            background-color: #999;
            cursor: pointer;
        }
        li.programLink.active .linkIcon{
            display: block;
            float: right;
        }
        li.programLink .linkIcon{
            display: none;
        }
        li.programLink:hover .linkIcon{
            display: block;
            float: right;
        }
        li.programLink:hover a, li.programLink.active a {
            color: white;
        }
    }

    .tagcloud {
        margin-bottom: 30px;
    }

    .social-share-block {
        border-bottom: 1px solid var(--color-border);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .post-like {
            padding-bottom: 20px;
            a {
                display: flex;
                align-items: center;
                i {
                    width: 40px;
                    height: 40px;
                    line-height: 34px;
                    border: 2px solid var(--color-border);
                    color: var(--color-body);
                    border-radius: 100%;
                    text-align: center;
                    margin-right: 20px;
                    @extend %transition;
                }
                span {
                    font-size: 16px;
                    line-height: 1.5;
                }
                &:hover {
                    i {
                        background-color: var(--color-primary);
                        color: var(--color-white);
                        border-color: var(--color-primary);
                    }
                    span {
                        color: var(--color-primary);
                    }
                }
            }
        }
        .social-icon {
            padding-bottom: 20px;
        }
    }

    iframe,
    .rbt-blockquote {
        border-radius: var(--radius) !important;
        &.square {
            border-radius: 0 !important;
        }
    }
}





// Tagcloud
.tagcloud {
    margin: -5px;
    display: flex;
    flex-wrap: wrap;
    a {
        border: 1px solid var(--color-border);
        font-size: 14px;
        color: var(--color-body);
        height: 30px;
        padding: 0 10px;
        margin: 5px;
        display: inline-block;
        line-height: 27px;
        border-radius: 4px;
        @extend %transition;
        &:hover {
            background-color: var(--color-primary);
            color: #ffffff;
            border-color: var(--color-primary);
        }
    }
}






// About Author 
.about-author {
    padding: 40px 0;
    border-bottom: 1px solid var(--color-border);
    .media {
        display: flex;
        @media #{$small-mobile} {
            display: block;
        }
    }
    .thumbnail {
        a {
            img {
                border-radius: 6px;
                margin-right: 30px;
                margin-bottom: 20px;
                min-width: 105px;
                max-height: 105px;
                @media #{$sm-layout} {
                    margin-right: 20px;
                }
            }
        }
    }
    .media-body {
        
        .author-info {
            .title {
                margin-bottom: 0;
                a {
                    @extend %transition;
                    .hover-flip-item {
                        span {
                            &::before {
                                color: var(--color-heading);
                            }
                            &::after {
                                color: var(--color-primary);
                            }
                        }
                    }
                }
            }
            .subtitle {
                color: var(--color-body);
                display: block;
                margin-top: 10px;
                display: block;
                @media #{$sm-layout} {
                    margin-top: 8px;
                }
            }
        }
        .content {
            .description {
                margin-top: 6px;
                margin-bottom: 0;
            }
            .social-icon {
                margin-top: 10px;
            }
        }
    }
}





.rbt-instructor {
    .about-author {
        .media {
            @media #{$sm-layout} {
                display: block;
            }
        }
        .thumbnail {
            @media #{$sm-layout} {
                margin-bottom: 30px;
            }
        }
    }
}

/*------------------------
    Comment Post Total  
---------------------------*/
.rbt-total-comment-post {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    border-bottom: 1px solid var(--color-border);
    @media #{$large-mobile} {
        display: block;
    }
    .add-comment-button {
        @media #{$large-mobile} {
            margin-top: 20px;
        }
    }
}

/*------------------------
    Comment Form  
-------------------------*/
.comment-respond {
    margin: 40px 0;
    position: relative;
    .comment-form-cookies-consent {
        margin-bottom: 20px;
    }

    .form-group  {
        label {
            font-size: 16px;
        }
    }
}

/* --------------------------
    Comments Styles  
-----------------------------*/

.comment-list {
    @extend %liststyle;
    ul {
        &.children {
            @extend %liststyle;
            padding-left: 75px;
            @media #{$sm-layout} {
                padding-left: 20px;
            }
        }
    }
    .comment {
        margin-top: 0;
        margin-bottom: 0;
        border-top: 1px solid var(--color-border);
       
        &:first-child {
            border-top: transparent;
        }

        .children {
            .comment {
                border-top: 1px solid var(--color-border);
            }
        }
        
        .single-comment {
            padding: 20px 0;
            display: flex;
            @media #{$large-mobile} {
                display: block;
            }
            .comment-img {
                margin-bottom: 15px;
                min-width: 70px;
                margin-right: 20px;
                max-width: 70px;
                img {
                    border-radius: 100%;
                    width: 100%;
                    background: var(--color-white);
                    padding: 4px;
                    border: 2px solid var(--primary-opacity);
                }
            }
        }

        .commenter {
            margin-bottom: 15px;
            line-height: 18px;
            a {
                .hover-flip-item {
                    span {
                        &::before {
                            color: var(--color-heading);
                        }
                        &::after {
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }

        .comment-meta {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            flex-wrap: wrap;
        }

        .time-spent {
            color: var(--color-heading);
            font-size: 12px;
            line-height: 18px;
        }

        .reply-edit {
            a {
                &.comment-reply-link {
                    font-size: 14px;
                    line-height: 18px;
                    display: flex;
                    color: var(--color-primary);
                    margin-left: 8px;
                    padding-left: 8px;
                    position: relative;
                    font-weight: 500;
                    overflow: visible;
                    @extend %transition;
                    .hover-flip-item {
                        span {
                            &::before {
                                color: var(--color-heading);
                            }
                            &::after {
                                color: var(--color-primary);
                            }
                        }
                    }
                    &:hover {
                        color: var(--color-primary);
                    }
                    &::before {
                        position: absolute;
                        content: "";
                        top: 50%;
                        transform: translateY(-50%);
                        left: -2px;
                        width: 4px;
                        height: 4px;
                        background: var(--color-heading);
                        border-radius: 100%;
                    }
                }
            }
        }

        .comment-text {
            p {
                color: var(--color-tertiary);
            }
        }
    }
}

.certification-section {
    height: 175px;
    overflow: hidden;
}


.blog-content-wrapper p li.programLink {
    @media #{$large-mobile} {
        width: auto;
    }    
}
